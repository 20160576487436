
import { Vue, Component, Model, Prop, Watch } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    GptAutoTypeAhead: () => getComponent('gpt/GptAutoTypeAhead'),
    GptAutoSuggestion: () => getComponent('gpt/GptAutoSuggestionV2'),
  },
})
export default class GptMagicBox extends Vue {
  @Model('change', { type: String }) dropdownValues!: any;

  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  waitingOnOptions!: any;

  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  aiCommentSuggestions!: any;

  @Prop({ type: String,
    default: 'What symptoms are you noticing ...' })
  placeholder!: any;

  gptTextQuery = '';

  get stringExistsInArray() {
    const lowerCaseSubStr = this.gptTextQuery.toLowerCase();

    return this.waitingOnOptions.some((item) =>
      lowerCaseSubStr.includes(item.toLowerCase())
    );
  }

  created() {
    this.gptTextQuery = this.dropdownValues;
  }


  get isWaitingOnType(){
    return this.gptTextQuery.length > 4 && this.stringExistsInArray
  }


  queryForSuggestion = false


  getSuggestions(){
    this.$emit('get-suggestions', this.gptTextQuery);
  }


  @Watch('isWaitingOnType',{immediate: true})
  onUpdateWatingOnType(val){
    this.$emit('update-waiting-on', val)
  }




  @Watch('gptTextQuery')
  onTextInputChange(value: string) {
    this.$emit('reset-gpt');
    if (value.length > 2) {
      if(this.stringExistsInArray){

        this.queryForSuggestion = true
      }else{
        this.queryForSuggestion = false
      }
      this.$emit('change', this.gptTextQuery);
      this.$emit('input', this.gptTextQuery);
    }else{
      this.$emit('change', this.gptTextQuery);
      this.$emit('reset-gpt');
    }
  }
}
